import React from "react"
import * as Icon from "react-feather"
import { Link } from "gatsby"

const ContactInfo = () => {
  return (
    <div className="contact-info-area ptb-80">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info-box">
              <div className="icon">
                <Icon.Mail />
              </div>
              <h3>Mail</h3>
              <p>
                <a href="mailto:nikhil@sanswebsolutions.com">
                  nikhil@sanswebsolutions.com
                </a>
                <br />
                <br />
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info-box">
              <div className="icon">
                <Icon.MapPin />
              </div>
              <h3>Offices</h3>
              <p style={{    textAlign: 'initial',padding: '0px 30px 0px 70px'}}>
              Indore, Madhya pradesh (India) <br/>
              Mumbai, Maharashtra (India) <br/>
              Houston Texas (USA)
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="contact-info-box">
              <div className="icon">
                <Icon.Phone />
              </div>
              <h3>Call/Whatsapp</h3>
              <p>
                <a href="https://wa.me/+919584860344" target="_blank">+91 9584860344</a>
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
