import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import ContactImg from "../../assets/images/contact-img.png";
import successfulImg from "../../assets/images/success.gif"
//Modal
import { Button, Modal, Col, Form, InputGroup, Row } from "react-bootstrap"
const ContactForm = () => {
    const [modalShow, setModalShow] = React.useState(false);

    const MyVerticallyCenteredModal = props => {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Your Requested Submitted
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={successfulImg} style={{ width: '8%' }} />
                    <h5>we will get back to you soon</h5>
                    <p></p>
                </Modal.Body>
            </Modal>
        )
    }

    const [validated, setValidated] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        let obj = {
            name: event.target.name.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            subject: event.target.subject.value,
            message: event.target.message.value,
        }
        setModalShow(true);
        const response = await fetch('https://sanswebsolutions.com/mail', {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: { 'Content-Type': 'application/json' }
        });
        const myJson = await response.json();
        // document.getElementById("tform").reset();
        // event.target.name.value = "";
        // event.target.email.value = "";
        // event.target.phone.value = "";
        // event.target.subject.value = "";
        // event.target.message.value = "";
        // setValidated(true);
        // setModalShow(true);
    };
    return (
        <div className="contact-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div className="bar"></div>
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
                </div>

                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <img src={ContactImg} alt="image" />
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <Form id="tform" noValidate validated={validated} onSubmit={(e) => {
                            !e.currentTarget.checkValidity() && e.preventDefault();
                            !e.currentTarget.checkValidity() && e.stopPropagation();
                            e.currentTarget.checkValidity() ? handleSubmit(e) : setValidated(true);
                        }}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input type="text" name="name" placeholder="Your Name" className="form-control"
                                            required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter your Name.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input type="text" name="email" placeholder="Your email address" className="form-control" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter your Email.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="phone" placeholder="Your phone number" className="form-control" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter your PhoneNumber.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="subject" placeholder="Your Subject" className="form-control"
                                            required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter your Subject.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea name="message" cols="30" rows="5" placeholder="Write your message..." className="form-control"
                                            required />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter your Message.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <button type="submit" className="btn btn-primary" >
                                        submit
                                    </button>
                                </div>
                            </div>

                        </Form>
                        <MyVerticallyCenteredModal show={modalShow} onHide={() => window.location.reload()} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactForm;