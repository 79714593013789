/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useEffect } from "react"

const Logging = ({ title }) => {
  useEffect(() => {
    callapi();
  }, []);

  const callapi = async () => {
    await fetch(`https://sanswebsolutions.com/${title}`);
  }

  return (
    null
  )
}

export default Logging
